import './TableButtons.scss';

import classNames from 'classnames';

export interface BaseTableButton {
  key: string;
  label: string;
  selected: boolean;
}

export interface TableButtonsProps<T extends BaseTableButton> {
  label: string;
  buttons: T[];
  onClickHandler?: (button: T) => void;
}

const TableButtons = <T extends BaseTableButton,>({ label, buttons, onClickHandler }: TableButtonsProps<T>) => {
  return (
    <div className="buttons-container p-3 d-flex align-items-center justify-items-between rounded-4 shadow-sm
                    text-nowrap me-3 mw-100">
      <div className="flex-grow-0 flex-shrink-0 me-2">
        { label }
      </div>
      <div className="flex-grow-1 flex-shrink-1 overflow-x-scroll">
        { buttons.map(button => {
            const classes = classNames({
              'd-inline-block': true,
              'mx-2': true,
              'py-2': true,
              'px-3': true,
              'rounded-pill': true,
              'rounded-4': true,
              'selected': button.selected
            });

            return (
              <span key={ button.key } className={ classes }
                    onClick={ () => onClickHandler && onClickHandler(button) }>
                { button.label }
              </span>
            );
        }) }
      </div>
    </div>
  );
};

export default TableButtons;
