export interface LayoutProps {
  children: any;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div id="app-content-container">
      { children }
    </div>
  );
};

export default Layout;
