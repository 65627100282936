import { useState } from "react";
import { MouseEvent } from 'react';

export default function usePagination() {
  const [currentPage, setCurrentPage] = useState(0);

  const hasPrevPage = () => currentPage > 0;
  const hasNextPage = (totalPages: number) => currentPage < totalPages - 1;
  const goToNext = (ev: MouseEvent, totalPages: number) => {
    ev.preventDefault();
    setCurrentPage((page: number) => Math.min(page + 1, totalPages))
  };
  const goToPrev = (ev: MouseEvent) => {
    ev.preventDefault();
    setCurrentPage((page: number) => Math.max(page - 1, 0));
  }
  const goToPage = (ev: MouseEvent, page: number) => {
    ev.preventDefault();
    setCurrentPage(page);
  }

  const pagination = { currentPage, hasNextPage, hasPrevPage, goToNext, goToPrev, goToPage, setCurrentPage };

  return pagination;
};
