import "./NotFound.scss";

import React from 'react';

const NotFound = (): React.ReactElement =>{
  return(
    <React.Fragment>
      <div id="app-content">
        <p>Esta página no existe</p>
      </div>
    </React.Fragment>
  )
}

export default NotFound;
