import classNames from "classnames";

const TableLoadingPlaceholder = ({ rows, columns }: { rows: number; columns: number  }) => {
  const rowIds = Array.from(Array(rows).keys());
  const columnIds = Array.from(Array(columns).keys());

  return (
    <>
      { rowIds.map(rowId => {
          return (
            <tr key={ rowId }>
              { columnIds.map(columnId=> {
                  const colClass = `col-${Math.floor(Math.random() * (8 - 4) + 4)}`;
                  const placeholderClasses = classNames({
                    [colClass]: true,
                    placeholder: true
                  });
                  return (
                    <td className="p-4 align-middle" key={ columnId }>
                      <p className="placeholder-glow mb-0">
                        <span className={ placeholderClasses }></span>
                      </p>
                    </td>
                  );
              }) }
            </tr>
          );
      }) }
    </>
  );
};

export default TableLoadingPlaceholder;
