import './InputText.scss';

import React, { forwardRef, InputHTMLAttributes } from 'react';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

const InputText  = forwardRef<HTMLInputElement, inputProps>(({ label, ...rest }, ref) => {
  return(
    <div>
      { label && <label>{label}</label> }
      <input type='text' { ...rest } ref={ ref } />
    </div>
  );
});

export default InputText;
