import React, { useEffect, useState } from 'react';

import Table, { buildRows, TableSortDirection, TABLE_SORT_DIRECTION_ASC, TABLE_SORT_DIRECTION_DESC } from '../../components/Table/Table';
import useApi, { ApiArrayResponse } from '../../hooks/useApi';
import usePagination from '../../hooks/usePagination';

export type CategoriesRow = {
  _id: string;
  [key: string]: string;
};

const Categories = () => {
  const [currentSorting, setCurrentSorting] = useState<{ column: string; direction: TableSortDirection }>({
    column: 'offers_count',
    direction: TABLE_SORT_DIRECTION_DESC
  });
  const { response: apiResponse, status: callApiStatus, callApi } = useApi<ApiArrayResponse<CategoriesRow>>(
    'business-category-stats'
  );
  const pagination = usePagination();

  const header = [
    'Nombre de la categoría',
    'Cantidad de ofertas',
    'Total de empresas que la emplean',
    'Ventas totales'
  ];

  let rows: CategoriesRow[] = [];
  let totalRows = 0;
  let shownRows = 0;

  if (apiResponse) {
    const { results, total, shownTotal } = apiResponse;
    totalRows = total;
    shownRows = shownTotal;
    rows = results;
  }

  useEffect(() => {
    callApi(currentSorting.column, currentSorting.direction, pagination.currentPage);
  }, [callApi, currentSorting, pagination.currentPage]);

  return (
    <React.Fragment>
      <div id="app-content">
        <div id="header" className="d-flex justify-content-between">
          <h1>Ofertas</h1>
          <span>Total de ofertas: { totalRows }</span>
        </div>
        <Table
          header={ header }
          rows={ buildRows(rows, ['name', 'offers_count', 'businesses_count', 'sales_count']) }
          sorting={ [
            {
              key: 'offers_count_desc',
              column: 'offers_count',
              label: 'Mayor número de ofertas',
              direction: TABLE_SORT_DIRECTION_DESC,
              selected: currentSorting.column === 'offers_count' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_DESC
            },
            {
              key: 'offers_count_asc',
              column: 'offers_count',
              label: 'Menor número de ofertas',
              direction: TABLE_SORT_DIRECTION_ASC,
              selected: currentSorting.column === 'offers_count' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_ASC
            },
            {
              key: 'businesses_count_desc',
              column: 'businesses_count',
              label: 'Mayor número de empresas',
              direction: TABLE_SORT_DIRECTION_DESC,
              selected: currentSorting.column === 'businesses_count' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_DESC
            },
            {
              key: 'businesses_count_asc',
              column: 'businesses_count',
              label: 'Menor número de empresas',
              direction: TABLE_SORT_DIRECTION_ASC,
              selected: currentSorting.column === 'businesses_count' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_ASC
            },
            {
              key: 'sales_desc',
              column: 'sales_count',
              label: 'Mayor número de ventas',
              direction: TABLE_SORT_DIRECTION_DESC,
              selected: currentSorting.column === 'sales_count' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_DESC
            },
            {
              key: 'sales_asc',
              column: 'sales_count',
              label: 'Menor número de ventas',
              direction: TABLE_SORT_DIRECTION_ASC,
              selected: currentSorting.column === 'sales_count' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_ASC
            }
          ] }
          onSort={ selectedSorting => setCurrentSorting(selectedSorting) }
          loading={ callApiStatus === 'pending' }
          pagination={ pagination }
          totalEntries={ shownRows }
        />
      </div>
    </React.Fragment>
  );
};

export default Categories;
