import React, { useEffect, useState } from 'react';

import Table, { buildRows, booleanFormatterFactory, TABLE_SORT_DIRECTION_ASC, TABLE_SORT_DIRECTION_DESC, TableSortDirection } from '../../components/Table/Table';
import useApi, { ApiArrayResponse } from '../../hooks/useApi';
import usePagination from '../../hooks/usePagination';

export type OffersRow = {
  _id: string;
  company: string;
  description: string;
  status: boolean;
  times_implemented: number;
};

const Offers = () => {
  const [currentSorting, setCurrentSorting] = useState<{ column: string; direction: TableSortDirection }>({
    column: 'times_implemented',
    direction: TABLE_SORT_DIRECTION_DESC
  });
  const [appliedFilter, setAppliedFilter] = useState<{ column: string; value: any }>(null!);
  const { status: callApiStatus, response: apiResponse, callApi } = useApi<ApiArrayResponse<OffersRow>>('promo-stats');
  const pagination = usePagination();

  const header = [
    'Empresa',
    'Descripción',
    'Estado',
    'Veces implementadas'
  ];

  let rows: OffersRow[] = [];
  let totalRows = 0;
  let shownRows = 0;

  if (apiResponse) {
    const { results, total, shownTotal } = apiResponse;
    totalRows = total;
    shownRows = shownTotal;
    rows = results;
  }

  useEffect(() => {
    callApi(currentSorting.column, currentSorting.direction, pagination.currentPage, '', appliedFilter);
  }, [callApi, currentSorting, pagination.currentPage, appliedFilter]);

  return (
    <React.Fragment>
      <div id="app-content">
        <div id="header" className="d-flex justify-content-between">
          <h1>Ofertas</h1>
          <span>Total de ofertas: { totalRows }</span>
        </div>
        <Table
          header={ header }
          rows={ buildRows(
            rows.map(row => ({
              ...row,
              status: booleanFormatterFactory(
                (<span className="d-inline-block rounded-pill rounded-4 p-0 ps-3 pe-3 active">
                  Activa
                </span>),
                (<span className="d-inline-block rounded-pill rounded-4 p-0 ps-3 pe-3 inactive">
                  Desactivada
                </span>)
              )(row['status'])
            })),
            ['company', 'description', 'status', 'times_implemented'])
          }
          sorting={ [
            {
              key: 'times_implemented_desc',
              column: 'times_implemented',
              label: 'Más consumidas',
              direction: TABLE_SORT_DIRECTION_DESC,
              selected: currentSorting.column === 'times_implemented' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_DESC
            },
            {
              key: 'times_implemented',
              column: 'times_implemented',
              label: 'Menos consumidas',
              direction: TABLE_SORT_DIRECTION_ASC,
              selected: currentSorting.column === 'times_implemented' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_ASC
            }
          ] }
          onSort={ selectedSorting => setCurrentSorting(selectedSorting) }
          loading={ callApiStatus === 'pending' }
          filters={ [
            {
              key: 'activas',
              label: 'Activas',
              filter: { column: 'status', value: true },
              selected: appliedFilter && appliedFilter?.value
            },
            {
              key: 'inactivas',
              label: 'Inactivas',
              filter: { column: 'status', value: false },
              selected: appliedFilter && !appliedFilter?.value
            }
          ] }
          onFilter={ filter => {
            pagination.setCurrentPage(0);
            setAppliedFilter(
              (filter && appliedFilter && filter.filter.column === appliedFilter.column &&
               filter.filter.value === appliedFilter.value) ?
              null! :
              filter.filter
            );
          } }
          pagination={ pagination }
          totalEntries={ shownRows }
          />
      </div>
    </React.Fragment>
  );
};

export default Offers;
