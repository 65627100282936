import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../auth/AuthProvider";
import { Filter } from "../components/Table/Table";

export interface ApiArrayResponse<T> {
  results: T[],
  total: number;
  shownTotal: number;
}

export default function useApi<ResponseType>(path: string) {
  const [response, setResponse] = useState<ResponseType>(null!);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState<string>(null!);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const callApi = useCallback(
    async (sortColumn: string, sortDirection: -1 | 1 = 1, page = 0, searchTerm = '', filter?: Filter) => {
      try {
        setStatus('pending');

        const filterParams = filter ?
          `&filter_column=${filter.column}&filter_value=${JSON.stringify(filter.value)}` : '';
        const searchParam = searchTerm ? `&search=${searchTerm}` : '';

        // const url = `http://localhost:3067/${path}?sort=${sortColumn}&dir=${sortDirection}&page=${page}${searchParam}${filterParams}`;
        const url = `https://troko-api.andu.codes/${path}?sort=${sortColumn}&dir=${sortDirection}&page=${page}${searchParam}${filterParams}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'token': `${user.token}`
          }
        });

        if (response.ok) {
          setResponse(await response.json());
          setStatus('success')
        } else {
          setError(await response.text());
          setStatus('error');
          if (response.status === 401) {
            await logout();
            navigate('/login', { state: { from: location } });
          }
        }
      } catch (e) {
        setError(JSON.stringify(e));
        setStatus('error');
      }
    },
    [path, user.token, location, logout, navigate]
  );

  return { callApi, status, error, response };
};
