import "./Button.scss";

import React, { ButtonHTMLAttributes } from "react";

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value?: string
}

const Button: React.FC<buttonProps> = ({ value, ...rest }) => {
  return(
    <div>
      <button type="button" { ...rest }>{value}</button>
    </div>
  );
}

export default Button
