import React, { useEffect, useState } from 'react';

import Table, { buildRows, currencyFormatter, TableSortDirection, TABLE_SORT_DIRECTION_ASC, TABLE_SORT_DIRECTION_DESC } from '../../components/Table/Table';
import useApi, { ApiArrayResponse } from '../../hooks/useApi';
import usePagination from '../../hooks/usePagination';

export type BusinessesRow = {
  _id: string;
  name: string;
  amount_paid: number;
  amount_invested: number;
  total_products: number;
  active_offers: number;
  inactive_offers: number;
  likes: number;
};

const Businesses = () => {
  const [currentSorting, setCurrentSorting] = useState<{ column: string; direction: TableSortDirection }>({
    column: 'total_products',
    direction: TABLE_SORT_DIRECTION_DESC
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { status: callApiStatus, response: apiResponse, callApi } = useApi<ApiArrayResponse<BusinessesRow>>(
    'business-stats'
  );
  const pagination = usePagination();

  const header = [
    'Empresa',
    'Dinero ingresado',
    'Dinero invertido',
    'Total de productos en venta',
    'Ofertas en activo',
    'Ofertas en inactivo',
    'Likes recibidos'
  ];

  let rows: BusinessesRow[] = [];
  let totalRows = 0;
  let shownRows = 0;

  if (apiResponse) {
    let { results, total, shownTotal } = apiResponse;
    totalRows = total;
    shownRows = shownTotal;
    rows = results;
  }

  useEffect(() => {
    callApi(currentSorting.column, currentSorting.direction, pagination.currentPage, searchTerm);
  }, [callApi, pagination.currentPage, currentSorting, searchTerm]);

  const handleSearch = (searchTerm: string) => {
    pagination.setCurrentPage(0);
    setSearchTerm(searchTerm);
  };

  return (
    <React.Fragment>
      <div id="app-content">
        <div id="header" className="d-flex justify-content-between">
          <h1>Empresas</h1>
          <span>Total de empresas: { totalRows }</span>
        </div>
        <Table
          header={ header }
          rows={ buildRows(
            rows.map(row => ({
              ...row,
              amount_paid: currencyFormatter(row['amount_paid']),
              amount_invested: currencyFormatter(row['amount_invested']),
            })),
            ['name', 'amount_paid', 'amount_invested', 'total_products', 'active_offers', 'inactive_offers', 'likes']
          ) }
          sorting={ [
            {
              key: 'total_products_desc',
              column: 'total_products',
              label: 'Empresas que más venden',
              direction: TABLE_SORT_DIRECTION_DESC,
              selected: currentSorting.column === 'total_products' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_DESC
            },
            {
              key: 'total_products_asc',
              column: 'total_products',
              label: 'Empresas que menos venden',
              direction: TABLE_SORT_DIRECTION_ASC,
              selected: currentSorting.column === 'total_products' &&
                        currentSorting.direction === TABLE_SORT_DIRECTION_ASC
            }
          ] }
          onSort={ selectedSorting => setCurrentSorting(selectedSorting) }
          onSearch={ handleSearch }
          loading={ callApiStatus === 'pending' }
          pagination={ pagination }
          totalEntries={ shownRows }
        />
      </div>
    </React.Fragment>
  );
};

export default Businesses;
