import './App.scss';

import { useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import Menu from './components/Menu/Menu';
import Businesses from './containers/Businesses/Businesses';
import Categories from './containers/Categories/Categories';
import Clients from './containers/Clients/Clients';
import Offers from './containers/Offers/Offers';
import Login from './containers/Login/Login';
import NotFound from './containers/NotFound/NotFound';
import AuthGuard from './auth/AuthGuard';
import Layout from './containers/Layout/Layout';
import { useAuth } from './auth/AuthProvider';
import { defaultMenuProps } from './constants/constants';
import { MenuProps } from './components/Menu/MenuTypes';

function App() {
  const location = useLocation();
  const path = location.pathname;
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps(path));
  const { user } = useAuth();

  const handleItemSelect = (selectedLink: string) => {
    setMenuProps(menuProps => ({
      ...menuProps,
      items: menuProps.items.map(item => ({ ...item, active: item.link === selectedLink }))
    }));
  };

  return (
    <div className="App">
      { user && (
          <div id="menu">
            <Menu items={ menuProps.items } onSelectItem={ handleItemSelect } />
          </div>
      ) }

      <Routes>
        <Route path='/' element={<Navigate to='/clientes' />} />
        <Route path="/login" element={ <Login /> } />
        <Route path="/clientes" element={ <AuthGuard><Layout><Clients /></Layout></AuthGuard> } />
        <Route path="/empresas" element={ <AuthGuard><Layout><Businesses /></Layout></AuthGuard> } />
        <Route path="/ofertas" element={ <AuthGuard><Layout><Offers /></Layout></AuthGuard> } />
        <Route path="/categorias" element={ <AuthGuard><Layout><Categories /></Layout></AuthGuard> } />
        <Route path='*' element={ <NotFound /> } />
      </Routes>
    </div>
  );
}

export default App;
