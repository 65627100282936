import { MenuProps } from "../components/Menu/MenuTypes"

export const defaultMenuProps = (path: string): MenuProps => ({
  items: [
    {
      title : 'Clientes',
      link : '/clientes',
      active : false,
      icon: 'clients'
    },
    {
      title : 'Empresas',
      link : '/empresas',
      active : false,
      icon: 'businesses'
    },
    {
      title : 'Ofertas',
      link : '/ofertas',
      active : false,
      icon: 'promos'
    },
    {
      title : 'Categorias de negocio',
      link : '/categorias',
      active : false,
      icon: 'categories'
    }
  ].map(
    item => {
      if (item.link === path || (item.link === '/clientes' && path === '/')) {
        item.active =true;
      } else {
        item.active = false;
      }

      return item;
  })
});
