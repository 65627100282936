import './Login.scss';

import React, { useEffect, useRef } from 'react';
import InputText from '../../components/InputText/InputText';
import Button from '../../components/Button/Button';
import { useAuth } from '../../auth/AuthProvider';
import { useLocation, useNavigate } from 'react-router';


const Login = (): React.ReactElement => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleLogin = async () => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (email && password) {
      await login(email, password);

      navigate(from, { replace: true });
    }
  };

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true });
    };
  }, [user, navigate]);

  return(
    <React.Fragment>
      <div id="login-container">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center">Trokocity CMS</h1>
              <InputText placeholder={'Introduce tu email'} ref={ emailRef }/>
              <InputText placeholder={'Introduce tu contraseña'} type="password" ref={ passwordRef } />
              <Button value="Entrar" onClick={ handleLogin } />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
