import { useAuth } from './AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default AuthGuard;
