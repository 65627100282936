import './Menu.scss';

import React, { MouseEvent } from 'react';
import { MenuProps, MenuItem } from './MenuTypes';
import logo_mano from '../../assets/images/logo_mano.svg';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import clientsActiveIcon from '../../assets/images/clients-active.svg';
import clientsIcon from '../../assets/images/clients.svg';
import businessesIcon from '../../assets/images/businesses.svg';
import businessesActiveIcon from '../../assets/images/businesses-active.svg';
import promosActiveIcon from '../../assets/images/promos-active.svg';
import categoriesActiveIcon from '../../assets/images/categories-active.svg';
import promosIcon from '../../assets/images/promos.svg';
import categoriesIcon from '../../assets/images/categories.svg';
import { useAuth } from '../../auth/AuthProvider';
import logoutIcon from '../../assets/images/logout.svg';

const getIcon = (menuItem: MenuItem) => {
  switch (menuItem.icon) {
    case 'clients':
      return menuItem.active ? clientsActiveIcon : clientsIcon;
    case 'businesses':
      return menuItem.active ? businessesActiveIcon : businessesIcon;
    case 'promos':
      return menuItem.active ? promosActiveIcon : promosIcon;
    case 'categories':
      return menuItem.active ? categoriesActiveIcon : categoriesIcon;
  }
};

const Menu = (menuProps: MenuProps): React.ReactElement => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = (ev: MouseEvent) => {
    ev.preventDefault();
    logout();
    navigate('/');
  };

  return (
    <div id="menu-container">
      <img src={logo_mano} className="logo" alt="" />

      <div className="d-flex flex-column justify-content-between">
        { menuProps.items && menuProps.items.map((item: MenuItem) => {
            const classes = classNames({
              'text-decoration-none': true,
              'fw-bold': true,
              'text-white': !item.active,
              'text-dark': item.active,
              'bg-white': item.active,
              'd-inline-block': true,
              'p-2': true,
              'w-100': true,
              'rounded': true,
              'rounded-4': true
            });

            return <p key={item.title}>
              <Link className={ classes } to={ item.link }
                    onClick={ () => menuProps.onSelectItem && menuProps.onSelectItem(item.link) }>
                <img src={ getIcon(item) } alt={ item.title } className="me-3" />
                { item.title }
              </Link>
            </p>
        }) }

        <a className="icon-link text-decoration-none text-secondary fw-bolder fixed-bottom ms-3 mb-3"
           onClick={ (ev) => handleLogout(ev) } href="#" >
          <img src={ logoutIcon } alt="logout" />
          Logout
        </a>
      </div>
    </div>
  );
}

export default Menu;
